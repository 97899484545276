.bg_signUp {
    padding-top: 80px;
    padding-bottom: 250px;
    background-image: url("../../Assets/Images/1440x782/HomePage.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../Assets/Images/1440x782/HomePage.jpg");
    background-image: -moz-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../Assets/Images/1440x782/HomePage.jpg");
    background-image: -o-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../Assets/Images/1440x782/HomePage.jpg");
    background-image: -ms-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../Assets/Images/1440x782/HomePage.jpg");
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0.4)),
            to(rgba(0, 0, 0, 0.8))),
        url("../../Assets/Images/1440x782/HomePage.jpg");
    background-image: -webkit-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../Assets/Images/1440x782/HomePage.jpg");
}

.form_signUp {
    padding: 40px;
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border-radius: 9px;
    background-color: #fff;
}