.navbar {
    background-color: transparent !important;
}

.navbar.active {
    background-color: #fff !important;
    -webkit-transition: background .8s ease-out;
    -moz-transition: background .8s ease-out;
    -o-transition: background .8s ease-out;
    transition: background .8s ease-out;
}

.banners-desktop {
    display: block;
}

.banners-tablet,
.banners-mobile {
    display: none;
}

@media (max-width: 991.5px) {
    .banners-desktop {
        display: none !important;
    }

    .banners-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banners-tablet {
        display: none !important;
    }

    .banners-mobile {
        display: block !important;
    }
}

.banners-desktop .carousel .slide img {
    max-height: 800px;
    width: 100%;
    object-fit: cover;
}

@media (max-width: 500px) {
    .carousel .control-dots {
        bottom: 40px !important;
    }
}