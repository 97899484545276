.index_mobile {
    display: none;
}

.image-container {
    bottom: -505px;
}

.position-absolute {
    position: absolute !important;
    width: 100%;
}

.position-relative {
    position: relative !important;
    text-align: center;
    z-index: 2;
    margin-bottom: 400px;
}

.card_floating {
    border-radius: 40px;
    background-color: #fff;
}

.image-container-logo {
    bottom: 0px;
}

.position-absolute-logo {
    position: absolute !important;
    width: 100%;
}

.position-relative-logo {
    position: relative !important;
    text-align: center;
    z-index: 1;
}

.cards {
    display: none !important;
}

@media (max-width: 500px) {
    .index_mobile {
        display: block;
    }

    .index_d_t {
        display: none;
    }
}

@media (max-width: 441px) {
    .image-container {
        bottom: -525px;
    }

    .position-relative {
        margin-bottom: 420px;
    }

}

@media (max-width: 435px) {
    .image-container {
        bottom: -555px;
    }

    .position-relative {
        margin-bottom: 450px;
    }
}

@media (max-width: 422px) {
    .image-container {
        bottom: -570px;
    }

    .position-relative {
        margin-bottom: 465px;
    }
}

@media (max-width: 375px) {
    .image-container {
        bottom: -230px;
    }

    .position-relative {
        margin-bottom: 125px;
    }

    .cards_index {
        display: none;
    }

    .cards {
        display: block !important;
    }
}

@media (max-width: 320px) {
    .position-relative {
        margin-bottom: 150px;
    }
}


.banner_img_container {
    position: relative !important;
    text-align: center;
    z-index: 1;
}

.logo_container {
    position: absolute !important;
    width: 100%;
}

.banner-container-logo {
    bottom: -100px;
}

.banner_img img {
    max-height: 660px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    object-position: center 50%;
}