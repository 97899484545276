.img-admin-sidebar-header {
    background-image: url("../../../Assets/Images/Portada.jfif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: linear-gradient(to bottom,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../../Assets/Images/Portada.jfif");
    background-image: -moz-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../../Assets/Images/Portada.jfif");
    background-image: -o-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../../Assets/Images/Portada.jfif");
    background-image: -ms-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../../Assets/Images/Portada.jfif");
    background-image: -webkit-gradient(linear,
            left top,
            left bottom,
            from(rgba(0, 0, 0, 0.4)),
            to(rgba(0, 0, 0, 0.8))),
        url("../../../Assets/Images/Portada.jfif");
    background-image: -webkit-linear-gradient(top,
            rgba(0, 0, 0, 0.4),
            rgba(0, 0, 0, 0.8)),
        url("../../../Assets/Images/Portada.jfif");
}

.bg-wrap {
    width: 100%;
    position: relative;
    z-index: 0;
    text-align: center;
    margin: 0;
    padding: 30px 0 30px 0;
}

.bg-wrap .user-logo .img-user-logo {
    width: 68px;
    height: 68px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.img-user-logo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}