.banner-products-desktop {
    display: block;
}

.banner-products-tablet,
.banner-products-mobile {
    display: none;
}

@media (max-width: 991.5px) {
    .banner-products-desktop {
        display: none !important;
    }

    .banner-products-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banner-products-tablet {
        display: none !important;
    }

    .banner-products-mobile {
        display: block !important;
    }
}

.banner-products-desktop img {
    max-height: 810px;
    width: 100%;
    object-fit: cover;
}

.position-relative-products {
    position: relative !important;
    z-index: 2;
    margin-bottom: 10px;
}

.position-absolute-products {
    position: absolute !important;
    width: 100%;
}

.image-container-products {
    bottom: -20px;
}

.card_floating-products {
    border-radius: 50px;
    background-color: #fff;
    height: 50px;
    width: 100%;
}

@media (min-width: 500px) {
    .card_floating-products {
        display: none;
    }

    #prod_page {
        margin-top: 40px;
    }
}
