.banner-about-desktop {
    display: block;
}

.banner-about-tablet,
.banner-about-mobile {
    display: none;
}

@media (max-width: 991.5px) {
    .banner-about-desktop {
        display: none !important;
    }

    .banner-about-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banner-about-tablet {
        display: none !important;
    }

    .banner-about-mobile {
        display: block !important;
    }
}

.banner-about-desktop img {
    max-height: 810px;
    width: 100%;
    object-fit: cover;
}

.position-relative-about {
    position: relative !important;
    z-index: 2;
    margin-bottom: 10px;
}

.position-absolute-about {
    position: absolute !important;
    width: 100%;
}

.image-container-about {
    bottom: -20px;
}

.card_floating-about {
    border-radius: 50px;
    background-color: #fff;
    height: 50px;
    width: 100%;
}

#about_page .swiper {
    padding-bottom: 45px !important;
}

.about_img_1 {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    object-position: center 15%;
    border-radius: 30px;
}

@media (min-width: 500px) {
    .card_floating-about {
        display: none;
    }

    #about_page {
        margin-top: 40px;
    }

}

@media (max-width:500px) {
    .about_img_1 {
        max-height: 85px;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        object-position: center 15%;
        border-radius: 30px;
    }
}

.competencies_card {
    box-shadow: 0px 0px 9px 5px rgba(0, 206, 145, .4);
    border-radius: 30px;
    width: 100%;
}

@media (max-width: 500px) {

    .competencies_card {
        height: auto !important;
    }

    .competencies_card_header {
        text-align: center;
    }
}