.full-img-profile {
    /* background-image: url(../../../assets/images/encabezado.jpg); */
    background-image: url(https://lh3.googleusercontent.com/d/12wyada6VsNgLYDMLA2OE_umnkKixvUw9);
    width: 100%;
    height: 350px;
    background-size: cover;
    box-shadow: 0 -2px 4px 2px rgba(0 0 0 / 20%);
}

.user-avatar-profile {
    position: absolute;
    top: 75%;
    left: 8%;
    font-weight: bold;
}

/* Box shadow tabs del perfil de usuario */
.box-shadow-profile {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
}

@media (max-width: 991.5px) {
    .user-avatar-profile {
        top: 100%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .info-profile {
        margin-left: 0 !important;
        margin-top: 70px !important;
    }
}

@media (max-width: 767.5px) {
    .info-profile {
        justify-content: center !important;
        text-align: center !important;
    }
}