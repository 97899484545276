.inf_box {
    box-shadow: 0 2px 4px 2px rgb(0 0 0 / 20%);
    border: 1px solid #F8F9F9;
    border-radius: 7px;
}

.inf_content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.inf_ini {
    display: flex;
    justify-content: flex-start;
    gap: 15px;
}

.img_inf {
    width: 70px;
    height: 70px;
    object-fit: cover;
}

@media (max-width: 580px) {
    .inf_ini {
        display: block !important;
    }
}

@media (max-width: 580px) {
    .inf_content {
        display: block !important;
        text-align: center;
    }

    .inf_ini img {
        width: 120px;
        height: 120px;
        margin-bottom: 5px;
    }

    .inf_ini {
        margin-bottom: 20px;
    }
}

.uploaded_image_inf {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 10px;
    height: 300px;
    width: 100%;
}

.uploaded_image_inf img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.upload_file_container_inf {
    position: relative;
    border: 2px dotted #283A49;
    padding: 120px 10px 120px 10px;
    border-radius: 7px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.upload_file_content_inf {
    text-align: center;
}