.myClass > img {
    width: 100%;
    object-fit: contain;
}

.myClass{
    word-break: break-word;
    text-align: justify;
}

/* .test {
    background-color: red;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
}

.test > img {
    width: 100%;
    object-fit: contain;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: blue;
} */