.banner-inv-desktop {
    display: block;
}

.banner-inv-tablet,
.banner-inv-mobile {
    display: none;
}

@media (max-width: 991.5px) {
    .banner-inv-desktop {
        display: none !important;
    }

    .banner-inv-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banner-inv-tablet {
        display: none !important;
    }

    .banner-inv-mobile {
        display: block !important;
    }
}

.banner-inv-desktop img {
    max-height: 810px;
    width: 100%;
    object-fit: cover;
}

.position-relative-inv {
    position: relative !important;
    z-index: 2;
    margin-bottom: 10px;
}

.position-absolute-inv {
    position: absolute !important;
    width: 100%;
}

.image-container-inv {
    bottom: -20px;
}

.card_floating-inv {
    border-radius: 50px;
    background-color: #fff;
    height: 50px;
    width: 100%;
}

.inv_img_card {
    cursor: pointer;
}

@media (max-width: 768px) {
    .inv_img_card {
        width: 65%;
    }
}

@media (max-width: 615px) {
    .inv_img_card {
        width: 80%;
    }
}

@media (max-width: 500px) {
    .inv_img_card {
        width: 100%;
    }
}

@media (min-width: 500px) {
    .card_floating-inv {
        display: none;
    }

    #inv_page {
        margin-top: 40px;
    }
}

.protocol_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 40px;
}

.protocol_img_name {
    display: flex;
    justify-content: flex-start;
    gap: 22px;
}

@media (max-width: 506px) {
    .protocol_container {
        justify-content: center;
        gap: 20px;
    }
}

@media (max-width: 400px) {

    .protocol_img_name {
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }

    .protocol_img_name img {
        width: 150px;
    }
}