.banner-ninAdmy-desktop {
    display: block;
}

.banner-ninAdmy-tablet,
.banner-ninAdmy-mobile {
    display: none;
}

@media (max-width: 991.5px) {
    .banner-ninAdmy-desktop {
        display: none !important;
    }

    .banner-ninAdmy-tablet {
        display: block !important;
    }
}

@media (max-width: 500px) {
    .banner-ninAdmy-tablet {
        display: none !important;
    }

    .banner-ninAdmy-mobile {
        display: block !important;
    }
}

.banner-ninAdmy-desktop img {
    max-height: 810px;
    width: 100%;
    object-fit: cover;
}

.position-relative-ninAdmy {
    position: relative !important;
    z-index: 2;
    margin-bottom: 10px;
}

.position-absolute-ninAdmy {
    position: absolute !important;
    width: 100%;
}

.image-container-ninAdmy {
    bottom: -20px;
}

.card_floating-ninAdmy {
    border-radius: 50px;
    background-color: #fff;
    height: 50px;
    width: 100%;
}

@media (min-width: 500px) {
    .card_floating-ninAdmy {
        display: none;
    }

    #ninAdmy_page {
        margin-top: 40px;
    }
}

.ninAcademy_img {
    max-height: 250px;
    width: 100%;
    object-fit: cover;
    vertical-align: middle;
    object-position: center 50%;
    border-radius: 30px;
    margin-top: 30px;
}

@media (max-width:500px) {
    .ninAcademy_img {
        max-height: 85px;
        width: 100%;
        object-fit: cover;
        vertical-align: middle;
        object-position: center 50%;
        border-radius: 30px;
    }
}