.webinar_upload_file_container {
    position: relative;
    border: 2px dotted #283A49;
    padding-top: 80px;
    padding-bottom: 80px;
    border-radius: 7px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
}

.webinar_upload_file_content {
    text-align: center;
}

.video_wrapper>video {
    width: 100%;
    height: 400px;
    object-fit: contain;
}

@media (max-width: 620px){
    .video_wrapper>video {
        height: 300px;
    }
}

@media (max-width: 500px){
    .video_wrapper>video {
        height: 200px;
        object-fit: contain;
    }
}